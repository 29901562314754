import { Badge, Group, NavLink, Text } from '@mantine/core'
import { IconBrandDiscordFilled } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import classes from '@/components/AppShellComponents/NavLink.module.css'
import config from '@/config'

export default function DiscordWithCounts() {
    const { data } = useQuery({
        queryKey: ['discord'],
        queryFn: async () => {
            const response = await fetch(
                'https://discord.com/api/v10/invites/bypass-city?with_counts=true'
            )
            return (await response.json()) as {
                approximate_member_count: number
                approximate_presence_count: number
            }
        },
    })

    return (
        <NavLink
            label={
                <Group grow>
                    <Text>Join Discord</Text>
                </Group>
            }
            component='a'
            href={config.discordInviteLink}
            target='_blank'
            color='violet'
            className={classes.navLink}
            variant='light'
            active
            rightSection={
                <Badge color='green' bg='gray' variant='outline'>
                    GET HELP
                </Badge>
            }
            leftSection={<IconBrandDiscordFilled size='1rem' stroke={1.5} />}
            description={
                data ? (
                    <>
                        <Text display='inline' c='green' size='sm'>
                            {data.approximate_presence_count}
                        </Text>
                        <Text display='inline' c='dimmed' size='sm'>
                            /{data.approximate_member_count} online
                        </Text>
                    </>
                ) : null
            }
        />
    )
}
